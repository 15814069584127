import React, { useEffect, useState } from "react";
import cococola from "../assets/icon/cocacola.svg";
import microsoft from "../assets/icon/microsoft.svg";
import ebay from "../assets/icon/ebay.svg";
import amazon from "../assets/icon/amazon.svg";
import ups from "../assets/icon/ups.svg";

const recruiters = [
    { name: "Coca Cola", logo: cococola },
    { name: "Microsoft", logo: microsoft },
    { name: "eBay", logo: ebay },
    { name: "Amazon", logo: amazon },
    { name: "UPS", logo: ups },
    { name: "Coca Cola", logo: cococola },
    { name: "Microsoft", logo: microsoft },
    { name: "eBay", logo: ebay },
    { name: "Amazon", logo: amazon },
    { name: "UPS", logo: ups },
];

const RecruitersSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsToShow = 7; // Number of items visible at a time

    // Auto-slide logic
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % recruiters.length);
        }, 4000); // Slide every 4 seconds
        return () => clearInterval(interval);
    }, []);

    // Generate visible items for infinite loop
    const visibleRecruiters = [];
    for (let i = 0; i < itemsToShow; i++) {
        visibleRecruiters.push(
            recruiters[(currentIndex + i) % recruiters.length]
        );
    }

    return (
        <div className="flex items-center justify-center py-10">
            <div className="text-center w-full">
                <h2 className="text-red-500 text-sm mb-4">Talent</h2>
                <h1 className="text-3xl font-bold mb-8">Top Talent Hired Through Our Platform</h1>
                <div className="overflow-hidden relative">
                    <div className="flex justify-center items-center transition-transform duration-500 ease-in-out mb-9 mt-3">
                        {visibleRecruiters.map((recruiter, index) => (
                            <div
                                key={index}
                                className="bg-white shadow-2xl p-8 rounded-xl flex items-center justify-center w-[250px] h-[250px] mx-6 flex-shrink-0"
                            >
                                <img
                                    src={recruiter.logo}
                                    alt={recruiter.name}
                                    className="h-16 w-auto object-contain"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecruitersSlider;
