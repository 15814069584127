import React, { useEffect, useState } from 'react';
import HomeHeader from '../components/HomeHeader'
import logo from '../assets/logo.png'
import detail from '../assets/job.png'
import '../styles/index.css'
import Pagination from '../components/Pagination';
import Recruiters from '../components/Recruiters';
import { getCompanyData } from '../api/api';
import { useNavigate } from 'react-router-dom';
import HomeFooter from '../components/HomeComponents/HomeFooter'
import HiringManagersBanner from '../components/HiringManagersBanner';
import JobReview from '../components/JobReview';
import TopPlaceEmployee from '../components/TopPlaceEmployee';


const Jobpage = () => {

  const navigate = useNavigate();

  const handleApplyClick = () => {
    navigate('/jobapply');
  };
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getCompanyData();
        if (data && data.length) {
          setCompanyData(data);
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getData();
  }, []);

  return (
    <div>
      <HomeHeader />

      <div className="image-container mt-10">
        <img src={detail} className='h-100' />
      </div>
      <div className="job-portal mt-[140px] sm:mt-20 md:mt-24 lg:mt-32">
        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-center">
          Job Portal by Coding Community
        </h1>


        <div className="stats grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8">
          <div className="flex flex-col items-center text-center">
            <span className="text-4xl font-bold">150+</span>
            <p>Organisations</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <span className="text-4xl font-bold">200+</span>
            <p>Job Openings</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <span className="text-4xl font-bold">6 LPA</span>
            <p>Avg Package</p>
          </div>
        </div>
      </div>


      <div className="filter-form">
        <form>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="company">Select Company</label>
              <select id="company" placeholder="selected company">
                <option>Selected Company</option>
                {companyData.length > 0 ? companyData.map((name, i) => (
                  <option key={name.id} value={name.id}>{name.company_name}</option>
                )) : ''}
                {/* <option>Google</option>
                <option>Microsoft</option>
                <option>Wipro</option>
                <option>Amazon</option>
                <option>Flipkart</option> */}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="experience">Experience</label>
              <select id="experience">
                <option>Select number of years</option>
                <option>1-2year</option>
                <option>2-4year</option>
                <option>4-6year</option>
                <option>6-8year</option>
                <option>8-10year</option>
                <option>10+year</option>
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="designation">Designation</label>
              <select id="designation">
                <option>Select your designation</option>
                <option>Cloud Engineer</option>
                <option>Data science</option>
                <option>Software developer</option>
                <option>UI/UX designer</option>
                <option>Data Analyst</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="salary">Salary Range</label>
              <select id="salary">
                <option>Salary</option>
                <option>10k</option>
                <option>20k</option>
                <option>30k</option>
                <option>40k</option>
                <option>50k</option>
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="location-type">Location Type</label>
              <select id="location-type">
                <option>Select location type</option>
                <option>Remote</option>
                <option>On-Site</option>
                <option>Hybrid</option>
              </select>
            </div>
            <div className="form-group location-group">
              <label htmlFor="location">Location</label>
              <select id="location">
                <option>Select location</option>
                <option>Mumbai</option>
                <option>Pune</option>
                <option>Delhi</option>
              </select>
            </div>
          </div>

          <div className="form-actions">
            <button type="button" className="clear">Clear</button>
            <button type="submit" className="apply">Apply</button>
          </div>
        </form>
      </div>

      <div className="job-listings">
        <div className="job-card">
          <img src={logo} alt="Company Logo" />
          <h3>Full Stack Developer</h3>
          <p className="tax2win">Tax2win</p>
          <p className="small">
            <span><i className="fa-solid job-icon fa-briefcase"></i>Fresher</span>
            <span> <i className="fa-regular job-icon fa-square"></i>Upto 10L p.a.</span>
            <span><i className="fa-solid job-icon fa-location-dot"></i>Jaipur</span>
          </p>
          <hr className="line" />
          <div className="side">
            <p>Apply before Sat Jul 20 2024</p>
            <button><a href="jobapply.html">Apply</a></button>
          </div>
        </div>

        <div className="job-card">
          <img src={logo} alt="Company Logo" />
          <h3>Full Stack Developer</h3>
          <p className="win">Tax2win</p>
          <p className="small">
            <span><i className="fa-solid job-icon fa-briefcase"></i>Fresher</span>
            <span> <i className="fa-regular job-icon fa-square"></i>Upto 10L p.a.</span>
            <span><i className="fa-solid job-icon fa-location-dot"></i>Jaipur</span>
          </p>
          <hr className="line" />
          <div className="side">
            <p>Apply before Sat Jul 20 2024</p>
            <button onClick={handleApplyClick}>Apply</button>
          </div>
        </div>

        <div className="job-card">
          <img src={logo} alt="Company Logo" />
          <h3>Full Stack Developer</h3>
          <p className="win">Tax2win</p>
          <p className="small">
            <span><i className="fa-solid job-icon fa-briefcase"></i>Fresher</span>
            <span> <i className="fa-regular job-icon fa-square"></i>Upto 10L p.a.</span>
            <span><i className="fa-solid job-icon fa-location-dot"></i>Jaipur</span>
          </p>
          <hr className="line" />
          <div className="side">
            <p>Apply before Sat Jul 20 2024</p>
            <button onClick={handleApplyClick}>Apply</button>
          </div>
        </div>

        <div className="job-card">
          <img src={logo} alt="Company Logo" />
          <h3>Full Stack Developer</h3>
          <p className="win">Tax2win</p>
          <p className="small">
            <span><i className="fa-solid job-icon fa-briefcase"></i>Fresher</span>
            <span> <i className="fa-regular job-icon fa-square"></i>Upto 10L p.a.</span>
            <span><i className="fa-solid job-icon fa-location-dot"></i>Jaipur</span>
          </p>
          <hr className="line" />
          <div className="side">
            <p>Apply before Sat Jul 20 2024</p>
            <button onClick={handleApplyClick}>Apply</button>
          </div>
        </div>

        <div className="job-card">
          <img src={logo} alt="Company Logo" />
          <h3>Full Stack Developer</h3>
          <p className="win">Tax2win</p>
          <p className="small">
            <span><i className="fa-solid job-icon fa-briefcase"></i>Fresher</span>
            <span> <i className="fa-regular job-icon fa-square"></i>Upto 10L p.a.</span>
            <span><i className="fa-solid job-icon fa-location-dot"></i>Jaipur</span>
          </p>
          <hr className="line" />
          <div className="side">
            <p>Apply before Sat Jul 20 2024</p>
            <button onClick={handleApplyClick}>Apply</button>
          </div>
        </div>
        <div className="job-card">
          <img src={logo} alt="Company Logo" />
          <h3>Full Stack Developer</h3>
          <p className="win">Tax2win</p>
          <p className="small">
            <span><i className="fa-solid job-icon fa-briefcase"></i>Fresher</span>
            <span> <i className="fa-regular job-icon fa-square"></i>Upto 10L p.a.</span>
            <span><i className="fa-solid job-icon fa-location-dot"></i>Jaipur</span>
          </p>
          <hr className="line" />
          <div className="side">
            <p>Apply before Sat Jul 20 2024</p>
            <button onClick={handleApplyClick}>Apply</button>
          </div>
        </div>
        <div className="job-card">
          <img src={logo} alt="Company Logo" />
          <h3>Full Stack Developer</h3>
          <p className="win">Tax2win</p>
          <p className="small">
            <span><i className="fa-solid job-icon fa-briefcase"></i>Fresher</span>
            <span> <i className="fa-regular job-icon fa-square"></i>Upto 10L p.a.</span>
            <span><i className="fa-solid job-icon fa-location-dot"></i>Jaipur</span>
          </p>
          <hr className="line" />
          <div className="side">
            <p>Apply before Sat Jul 20 2024</p>
            <button onClick={handleApplyClick}>Apply</button>
          </div>
        </div>
        <div className="job-card">
          <img src={logo} alt="Company Logo" />
          <h3>Full Stack Developer</h3>
          <p className="win">Tax2win</p>
          <p className="small">
            <span><i className="fa-solid job-icon fa-briefcase"></i>Fresher</span>
            <span> <i className="fa-regular job-icon fa-square"></i>Upto 10L p.a.</span>
            <span><i className="fa-solid job-icon fa-location-dot"></i>Jaipur</span>
          </p>
          <hr className="line" />
          <div className="side">
            <p>Apply before Sat Jul 20 2024</p>
            <button onClick={handleApplyClick}>Apply</button>
          </div>
        </div>
      </div>

      <Pagination />
      <Recruiters />
      <HiringManagersBanner />
      <JobReview />
      <TopPlaceEmployee />
      <HomeFooter />


    </div>
  );
};

export default Jobpage;
