import axios from 'axios';

export const getCompanyData = async () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
        const response = await axios.get(`${baseUrl}/company`);

        if (response.data && response.data.data) {
            return response.data.data;
        } else {
            console.error('Not able to get the data', response.data);
        }
    } catch (error) {
        console.error('Failed to fetch data', error);
    }
}
