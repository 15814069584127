
import React from 'react';
import error from "../assets/404p.png"

const NotFoundPage = () => {
  return (
    <section>
      <div classNameName="main container">
        <div classNameName="row">
          <div classNameName="col-12 col-sm-12 col-md-12 col-lg-3"></div>

          <div classNameName="col-12 col-sm-12 col-md-12 col-lg-6">
            <div classNameName="main-img text-center">
              <img src={error} alt="Main" classNameName="img-fluid pb-3" />

              <h2>Oops, something went wrong</h2>
              <p classNameName="main-description pt-2">We're sorry, the page you requested could not be found. Please go back to the homepage.</p>
              <button classNameName="btn btn-sz-primary" onClick={() => window.location.href = '/'}>Go Home</button>
            </div>
          </div>

          <div classNameName="col-12 col-sm-12 col-md-12 col-lg-3"></div>
        </div>
      </div>



      <div className="info-container">
        <div className="info-box">
          <i className="fas fa-code icon learn-coding" title="Learn Coding"></i>
          <h2>Learn Coding</h2>
          <p>Learn coding online from anywhere in the world.</p>
        </div>
        <div className="info-box">
          <i className="fas fa-users icon online-community" title="Online Community"></i>
          <h2>Online Community</h2>
          <p>Share and get real time advice from industry professionals.</p>
        </div>
        <div className="info-box">
          <i className="fas fa-briefcase icon jobs" title="Jobs"></i>
          <h2>Jobs</h2>
          <p>Get access to jobs and projects from around the world.</p>
        </div>
        <div className="info-box">
          <i className="fas fa-newspaper icon trending-news" title="Trending News"></i>
          <h2>Trending News</h2>
          <p>Get trending news and information about the tech community.</p>
        </div>
      </div>



    </section>
  );
};

export default NotFoundPage;
