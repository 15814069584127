import React from "react";

const HiringManagersBanner = () => {
  return (
    <div className="flex items-center justify-center p-4 relative w-full"> {/* Ensures the container takes full width */}
      <div className="flex flex-col md:flex-row items-center justify-between bg-orange-500 text-white rounded-lg py-6 px-8 shadow-lg w-full max-w-9xl h-[350px]"> {/* Adjusted height */}
        {/* Left Section - Illustration */}
        <div className="flex items-center space-x-6 mb-4 md:mb-0 flex-grow"> {/* Added space-x-6 to increase gap */}
          <div className="bg-gray-100 p-4 rounded-full">
            {/* Illustration (You can replace this SVG with the specific image or icon) */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 12c3.866 0 7-3.134 7-7S15.866 2 12 2 5 5.134 5 9s3.134 7 7 7zm0 4c-4.418 0-8 2.686-8 6h16c0-3.314-3.582-6-8-6z"
              />
            </svg>
          </div>
          <div>
            <div className="text-2xl font-bold">For Hiring Managers</div>
            <div className="text-sm mt-0.5">
              Looking for a new job can be both exciting and daunting. Navigating
              the job market involves exploring various avenues.
            </div>
          </div>
        </div>
        {/* Right Section - Button */}
        <div className="flex-grow text-center md:text-right">
          <button className="bg-white text-green-500 font-semibold py-2 px-4 rounded-md hover:bg-gray-200 transition">
            Job Post For Free
          </button>
        </div>
      </div>
    </div>
  );
};

export default HiringManagersBanner;
